<template>
    <Header/>
    <Consultingheader/>
    <Consultingservice/>
    <Footer/>
</template>

<script>

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Consultingservice from '@/components/Consultingservice.vue'
import Consultingheader from '@/components/Consultingheader.vue'

export default {
  components: {
     Header,
    Footer,
    Consultingservice,
    Consultingheader
  }
}
</script>