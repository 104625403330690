<template>
    <section id="services" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>What We Do</h2>
          <p style="padding-top:20px">Technology is the core of all our basic changes that we witness around us. When the world is moving a step ahead digitally, we help and consult our clients on the various forms of technological advancements that are available. We ensure client satisfaction and innovate their way of promoting their business.</p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-md-3 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4>{{ obj.header }}</h4>
                <p>{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/Information-Technology.jpg',
        header: 'Information Technology',
        caption: 'With the wave of technology taking over most of the business, we provide services and consult our clients on the various benefits of using information technology to provide legitimate business outcomes generated in a faster pace. The age of computer and its technological benefits are shared.'
      },
      {
        path: 'services/Advanced-Analytics.jpg',
        header: 'Advanced Analytics',
        caption: 'We consult on technological analytics that involve big data, cloud computing for large businesses. Our teams of professionals consult on various strategical approaches that are best for the business after having considered their overall growth. We help companies achieve the competitive edge in today’s world.'
      },
      {
        path: 'services/Digital-Marketing.jpg',
        header: 'Digital marketing',
        caption: 'Digital Marketing has been there as long as internet but the face of marketing keeps changing over the years and our clients are consulted on the added pros of marketing digitally to promote their product and business online and reach out to the wider masses in a short period of time.'
      },
      {
        path: 'services/Sustainability.jpg',
        header: 'Sustainability',
        caption: 'For any Company, sustainability is the key to progress in time and in order to sustain a growing company, one need to incorporate all the technological advancements. This will sustain them in the ever growing competitive world of business, marketing, promoting and branding a name for them to be known.'
      }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#services {
    padding: 50px 0px 10px 0px;
}
.services .icon-box i {
  float: left;
  color: #f6b024;
  font-size: 40px;
  line-height: 0;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>